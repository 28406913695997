import * as React from "react"
import { Link } from "gatsby"
import Layout from "../layout"

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <title>Not found</title>
      <p class="prompt prompt_404" id="cat"></p>

      <h1>Page not found</h1>

			<section>
	      Sorry{" "}
                <span role="img" aria-label="Pensive emoji">
                  😔
                </span>{" "}
                we couldn’t find what you were looking for.
                <br />
                <Link to="/">Go home</Link>.
      </section>
    </Layout>
  )
}

export default NotFoundPage
